import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/phastekperformance/Templates/SearchPage.rt'
import TemplateCategoryPage from 'Stores/phastekperformance/Templates/CategoryPage.rt'
import TemplateStubSearchPage from 'Stores/phastekperformance/Templates/StubSearchPage.rt'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchpageTitle from 'Stores/phastekperformance/Templates/fitmentSearch/pageTitle.rt'
import { SearchHeader } from 'Components'
import { SearchResult } from 'Components'
import { FacetPanel } from 'Components'
import TemplateFacetPanelContainer from 'Stores/_default-store/Templates/FacetPanelContainer.rt'
import { FacetBar } from 'Components'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/_default-store/Templates/SearchBox.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import TemplatefitmentSearchcollapsibleVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/collapsibleVehicleWidget.rt'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/_default-store/Templates/fitmentSearch/garage.rt'
import { SingleVehicleGarage } from 'Components'
import TemplatefitmentSearchsingleVehicleGarage from 'Stores/phastekperformance/Templates/fitmentSearch/singleVehicleGarage.rt'
import { FitmentTable } from 'Components'
import TemplatefitmentSearchfitmentTable from 'Stores/_default-store/Templates/fitmentSearch/fitmentTable.rt'
import { VerifyFitment } from 'Components'
import TemplatefitmentSearchverifyFitment from 'Stores/_default-store/Templates/fitmentSearch/verifyFitment.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialogVehicleSpecs from 'Stores/_default-store/Templates/FacetDialogVehicleSpecs.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { VehicleWidgetDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/phastekperformance/Templates/fitmentSearch/dialog.rt'

export const compMap = {
  SearchPage,
VehicleWidget,
SearchHeader,
SearchResult,
FacetPanel,
FacetBar,
SearchBox,
SearchBoxDialogButton,
Garage,
SingleVehicleGarage,
FitmentTable,
VerifyFitment,
FacetDialog,
SearchBoxDialog,
VehicleWidgetDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'CategoryPage': TemplateCategoryPage,
'StubSearchPage': TemplateStubSearchPage,
'fitmentSearch/pageTitle': TemplatefitmentSearchpageTitle,
'FacetPanelContainer': TemplateFacetPanelContainer,
'SearchBox': TemplateSearchBox,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'fitmentSearch/collapsibleVehicleWidget': TemplatefitmentSearchcollapsibleVehicleWidget,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'fitmentSearch/singleVehicleGarage': TemplatefitmentSearchsingleVehicleGarage,
'fitmentSearch/fitmentTable': TemplatefitmentSearchfitmentTable,
'fitmentSearch/verifyFitment': TemplatefitmentSearchverifyFitment,
'FacetDialogVehicleSpecs': TemplateFacetDialogVehicleSpecs,
'SearchBoxDialog': TemplateSearchBoxDialog,
'fitmentSearch/dialog': TemplatefitmentSearchdialog
};