export default {
  BUTTON_PRIMARY_CLASS: 'button button--primary',
  BUTTON_SECONDARY_CLASS: 'button',

  PRODUCT_REPEATER_CLASS: `product-{{ view }}{this.view === 'grid-view' ? ' productGrid' : ''}`,

  FACET_TITLE_ICON: `
    <rt-virtual rt-if="this.isCollapsed"><svg class="cm_icon icon"><use xlinkHref="#icon-add"></use></svg></rt-virtual>
    <rt-virtual rt-if="!this.isCollapsed"><svg class="cm_icon icon"><use xlinkHref="#icon-remove"></use></svg></rt-virtual>`,
  FACET_TOGGLE_LABEL_CLASS: 'form-label',

  SELECT_YOUR_VEHICLE: 'Choose Your Vehicle',
  SELECT_YOUR_VEHICLE_BLOCK: 'Choose Your Vehicle',
  VEHICLE_WIDGET_COLLAPSIBLE_CLASS: 'container',

  POPULAR_VALUES: '',

  SEARCH_BOX_PLACEHOLDER:
    '{window.innerWidth < 1200 ? "Search" : "Search by Make Model Year, Product Type, Part Number"}',
  SEARCH_BOX_FORM_CLASS: 'form-group',
  SEARCH_BOX_INPUT_CLASS: 'form-input snize-input-style form-control',
  SEARCH_BOX_BUTTON_CLASS: 'search-icon',
};
